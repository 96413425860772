<script setup lang="ts">
import SharedBandSignupWidgetTemplate from '~/components/shared/bandSignup/widgetTemplate.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useGetProfilePicture } from '~/composables/useGetProfilePicture'

import { useCartStore } from '~/stores/cart'
import { useMiscBandSignupStore } from '~/stores/miscBandSignup'

import { provideGetCuratorById } from '~/api-core/Curators/GetCuratorById'

const { query, params } = useRoute()
const segmentTrack = useSegmentTrack()
const { getProfilePictureUrl } = useGetProfilePicture()
const localePath = useLocalePath()

const { SET_WIDGET_CONTEXT: BAND_SIGNUP_SET_WIDGET_CONTEXT } =
  useMiscBandSignupStore()
const { ADD_INF } = useCartStore()

onMounted(() => trackPageAndSetupWidgetContext())

const { data: influencer } = await useAsyncData('influencerId', async () => {
  const influencerId = Number(params.code)

  if (Number.isNaN(influencerId) || influencerId <= 0) {
    await navigateTo(localePath('/'))
    return null
  }

  const getCuratorById = provideGetCuratorById($coreFetch)
  const influencer = await getCuratorById(influencerId)

  if (!influencer) {
    await navigateTo(localePath('/'))
    return null
  }

  if (influencer.visible) ADD_INF(influencer.id).catch(() => null)

  return influencer
})

const picture = computed(() => {
  if (!influencer.value) return undefined
  return getProfilePictureUrl({
    kind: 'influencer',
    target: 'profile_picture',
    size: '200_200',
    slug: influencer.value.has_profile_picture
      ? influencer.value.slug
      : undefined,
  })
})

function trackPageAndSetupWidgetContext() {
  if (!influencer.value) return

  segmentTrack('Referral - Sign Up Page Visited', {
    curator_id: influencer.value.id,
    referred_from: query.from_widget === 'true' ? 'widget' : 'link',
  })
  BAND_SIGNUP_SET_WIDGET_CONTEXT({
    entityId: influencer.value.id,
    userKind: 'influencer',
  })
}
</script>

<template>
  <div id="ignoreFontUpscale" class="ignoreFontUpscale">
    <SharedBandSignupWidgetTemplate
      v-if="influencer"
      :picture="picture ?? ''"
      :entity-name="influencer.entity"
      user-kind="influencer"
    />
  </div>
</template>
